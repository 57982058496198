import { Service } from "../../config/service";

class TransferService extends Service {
  constructor() {
    super("transfer");
  }

  //add Transfer
  async addTransfer(body) {
    try {
      this.getResource().insert += "/join/setup";
      const { data } = await this.insert(body);
    } catch (e) {
      console.error(e);
    }
  }

  //delete Transfer
  async deleteTransfer(id) {
    try {
      this.getResource().delete += "/join/setup";
      this.setId(id);
      const { data } = await this.delete();
    } catch (e) {
      console.error(e);
    }
  }

  async getTransferByAppraisal(id) {
    try {
      this.getResource().get += "/join/setup/appraisal";
      this.setId(id);
      const { data } = await this.get();
      return data;
    } catch (e) {
      console.error(e);
    }
  }
}

export default TransferService;
